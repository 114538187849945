interface Config {
    environment: string;
    apiUrl: string;
    sentry: {
        dsn?: string;
    };
}

export const config: Config = {
    environment: process.env.ENVIRONMENT || 'local',
    apiUrl: process.env.API_URL || 'https://localhost:4000/local',
    sentry: {
        dsn: 'https://6d8efe5425b28b6a0b64dda4917caebe@o355351.ingest.us.sentry.io/4507248695443456',
    },
};
